import React, { useContext } from "react";
import { Link } from "react-router-dom";
import './WebButton.css'
import { useAppContext } from "../Context/AppContext";

const ViewMoreButtonDesktop = (props) => {
    const { BASE_PATH } = useContext(useAppContext);

    const { redirect } = props;

    if (!redirect) {
        return <></>
    }


    return (
        <Link className="webbutton d-none d-lg-block" to={`${BASE_PATH}${redirect}`}>View More</Link>
    )

}
export const ViewMoreButtonMobile = (props) => {
    const { BASE_PATH } = useContext(useAppContext);

    const { redirect } = props;
    return (
        <Link className="webbutton2 d-lg-none d-block" to={`${BASE_PATH}${redirect}`}>View More</Link>
    )

}

export default ViewMoreButtonDesktop;
