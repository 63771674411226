import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useAppContext } from '../Context/AppContext';
import youtube from '../logos/youtube.png'

const YoutubeVideosCard = (props) => {
    const { BASE_PATH } = useContext(useAppContext);
    const { articleurl, categorytitle, categoryurl, date, image, title } = props.data;
    return (
        <Col lg={4} md={4} sm={6} xs={6} className='p-2' >
            <Card >
                <div className='position-relative'>
                    <Card.Img variant="top" src={image} alt={title} loading="lazy" />
                    <Card.Link className='icon' href={`${BASE_PATH}${categoryurl}/${articleurl}`}>
                        <img src={youtube} alt="youtube" />
                    </Card.Link>
                </div>
                <Card.Body>
                    <Card.Text className='card-category'>{categorytitle} </Card.Text>
                    <Card.Link href={`${BASE_PATH}${categoryurl}/${articleurl}`}>
                        <Card.Title className='word-line-limit'>{title}</Card.Title>
                    </Card.Link>
                </Card.Body>
            </Card>
        </Col >
    );
}

export default YoutubeVideosCard;







