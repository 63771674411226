import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useAppContext } from '../Context/AppContext';

const CategoryCard = (props) => {
    const { data } = props;
    const { image, title, articleurl, categoryurl, categorytitle } = data;
    const { BASE_PATH } = useContext(useAppContext);

    return (
        <Col lg={3} md={6} sm={6} xs={6} className='p-2'>
            <Card >
                <Card.Img variant="top" src={image} alt={data.url} loading="lazy" />
                <Card.Body>
                    <Card.Text className='card-category'>{categorytitle} </Card.Text>
                    <Card.Link href={`${BASE_PATH}${categoryurl}/${articleurl}`}>
                        <Card.Title className='word-line-limit'>{title}</Card.Title>
                    </Card.Link>
                </Card.Body>
            </Card>
        </Col >
    );
}

export default CategoryCard;







