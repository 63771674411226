import React, { useContext } from "react";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import './StorieSlider2.css'
import ViewMoreButtonDesktop, { ViewMoreButtonMobile } from "../WebButton/ViewMoreButton";
import { useAppContext } from "../Context/AppContext";

const StorieSlider2 = (props) => {
    const { children, title, url } = props;

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    const { BASE_PATH } = useContext(useAppContext);
    return (
        <section className='storieslider2'>

            <Container className='card-section'>
                <Row>
                    <div className="whitetitle">
                        <h2>{title}</h2>
                        <ViewMoreButtonDesktop redirect={url} />
                    </div>
                </Row>
                <Carousel responsive={responsive} autoPlaySpeed={2000} autoPlay={true} infinite={true}>
                    {
                        children.map((list, index) => {
                            const { articleurl, categorytitle, categoryurl, date, image, title } = list;
                            return (
                                <Row key={index} className="slidecardrow p-2">
                                    <Col lg={12} md={12} sm={12} xs={12} >
                                        <Card.Link href={`${BASE_PATH}${categoryurl}/${articleurl}`}>
                                            <img src={image} alt={index} loading="lazy" />
                                        </Card.Link>
                                        <Card.Body>
                                            <Card.Text className='card-category'>{categorytitle} </Card.Text>
                                            <Card.Link href={`${BASE_PATH}${categoryurl}/${articleurl}`}>
                                                <Card.Title className='word-line-limit'>{title}</Card.Title>
                                            </Card.Link>
                                        </Card.Body>
                                    </Col>

                                </Row >
                            )
                        })
                    }
                </Carousel>
                <ViewMoreButtonMobile redirect={url} />
            </Container >
        </section >
    )
}

export default StorieSlider2;

