import React from "react";
import './Share.css'
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";


const Share = (props) => {

    const { shareUrl, title } = props;

    return (
        <div className="Share__container">
            <div className="Share__some-network">
                <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="Share__some-network__share-button"
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>

            </div>

            <div className="Share__some-network">
                <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    className="Share__some-network__share-button"
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
            </div>

            <div className="Share__some-network">
                <TelegramShareButton
                    url={shareUrl}
                    title={title}
                    className="Share__some-network__share-button"
                >
                    <TelegramIcon size={32} round />
                </TelegramShareButton>
            </div>

            <div className="Share__some-network">
                <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                    className="Share__some-network__share-button"
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </div>

            <div className="Share__some-network">
                <LinkedinShareButton url={shareUrl} className="Share__some-network__share-button">
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
            </div>

            <div className="Share__some-network">
                <RedditShareButton
                    url={shareUrl}
                    title={title}
                    windowWidth={660}
                    windowHeight={460}
                    className="Share__some-network__share-button"
                >
                    <RedditIcon size={32} round />
                </RedditShareButton>

            </div>

            <div className="Share__some-network">
                <TumblrShareButton
                    url={shareUrl}
                    title={title}
                    className="Share__some-network__share-button"
                >
                    <TumblrIcon size={32} round />
                </TumblrShareButton>

            </div>


            <div className="Share__some-network">
                <ViberShareButton
                    url={shareUrl}
                    title={title}
                    className="Share__some-network__share-button"
                >
                    <ViberIcon size={32} round />
                </ViberShareButton>
            </div>

            <div className="Share__some-network">
                <LineShareButton
                    url={shareUrl}
                    title={title}
                    className="Share__some-network__share-button"
                >
                    <LineIcon size={32} round />
                </LineShareButton>
            </div>

        </div>
    );
}

export default Share;