import React from "react";
import { Container } from "react-bootstrap";


const Error = () => {
    return (
        <Container>
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div >
                    <h1>404 Error</h1>
                    <p>Page Not found</p>
                </div>
            </div>
        </Container>
    )
}

export default Error;