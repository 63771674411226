
import React from "react";
import { Helmet } from "react-helmet-async";
import Cookies from 'js-cookie';
const Meta = (props) => {
    const { title, desc, keyword, canonical, image, author, language } = props.metadata;

    if (Cookies.get('lg') != language && language != null) {
        Cookies.set('lg', language);
        window.location.reload();
    }

    return (
        <>
            <Helmet>
                <title> {title}</title>
                <meta property='og:title' content={title} data-react-helmet="true" />
                <meta name='twitter:title' content={title} data-react-helmet="true" />
                <meta property='og:site_name' content={title} data-react-helmet="true" />

                <meta name="keywords" content={keyword} data-react-helmet="true" />
                <meta name="author" content={author} data-react-helmet="true" />

                <link rel='canonical' href={canonical} data-react-helmet="true" />
                <meta property='og:url' content={canonical} data-react-helmet="true" />
                <meta name='twitter:url' content={canonical} data-react-helmet="true" />

                <meta name="description" content={desc} data-react-helmet="true" />
                <meta property='og:description' content={desc} data-react-helmet="true" />
                <meta name='twitter:description' content={desc} data-react-helmet="true" />

                <meta property="og:image" content={image} data-react-helmet="true" />
                <meta name="twitter:image" content={image} data-react-helmet="true" />
            </Helmet>
        </>
    )
}

export default Meta;
