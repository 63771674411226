import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { useAppContext } from "../Context/AppContext";

const StoriesCard = (props) => {
    const { carddata } = props;
    const { articleurl, categorytitle, categoryurl, date, image, title } = carddata;
    const { BASE_PATH } = useContext(useAppContext);
    return (
        <>
            <Card >
                <Card.Img variant="top" src={image} alt={title} loading="lazy" />
                <Card.Body>
                    <Card.Text className='card-category'>{categorytitle} </Card.Text>
                    <Card.Link href={`${BASE_PATH}${categoryurl}/${articleurl}`}>
                        <Card.Title className='word-line-limit'>{title}</Card.Title>
                    </Card.Link>
                </Card.Body>
            </Card>
        </>
    )
}

export default StoriesCard;