import React, { useEffect } from "react";
const HorizontalAd = (props) => {
    const { adslot, adsizes, adnumber } = props;
    useEffect(() => {
        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(function () {
            window.googletag.defineSlot(adslot, adsizes, 'h-ad' + adnumber)
                .addService(window.googletag.pubads().set('page_url', 'mobilemasala.com'));
            window.googletag.enableServices();
        });
        return () => {
            window.googletag = null;
        };
    }, []);

    useEffect(() => {
        if (window.googletag) {
            window.googletag.cmd.push(function () {
                window.googletag.display('h-ad' + adnumber);
            });
        }
    }, []);

    return (
        <div className="desktop_ads_block text-center" style={{ backgroundColor: 'white', margin: '10px' }}>
            <div id={`h-ad${adnumber}`}></div>
        </div>

    )
}

export default HorizontalAd;