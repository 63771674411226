import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './ShortVideos.css';
import ViewMoreButtonDesktop, { ViewMoreButtonMobile } from '../WebButton/ViewMoreButton';

const ShortVideos = (props) => {
    const { children, title, url } = props;
    return (
        <>
            <section className='shortvideos'>
                <Container className='card-section'>
                    <Row>
                        <div className='whitetitle'>
                            <h2>{title}</h2>
                            <ViewMoreButtonDesktop redirect={url} />
                        </div>
                    </Row>
                    <Row>
                        {
                            children.map((data, index) => {
                                return (
                                    < Col lg={3} md={6} sm={6} xs={6} className='p-1' key={index} >
                                        <video loop={true} autoPlay="autoplay" muted="on">
                                            <source src={data.video} type="video/mp4" />
                                        </video>
                                    </Col >
                                )
                            })
                        }
                    </Row>
                    <ViewMoreButtonMobile redirect={url} />
                </Container>
            </section >
        </>
    );
}

export default ShortVideos;