import React, { useContext } from "react";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './SlideCard2.css'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ViewMoreButtonDesktop, { ViewMoreButtonMobile } from "../../WebButton/ViewMoreButton";
import { useAppContext } from "../../Context/AppContext";


const SlideCards2 = (props) => {
    const { children, title, url } = props;

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const { BASE_PATH } = useContext(useAppContext);
    return (
        <section className='slidecard2'>

            <Container className='card-section'>
                <Row>
                    <div className="whitetitle">
                        <h2>{title}</h2>
                        <ViewMoreButtonDesktop redirect={url} />
                    </div>
                </Row>
                <Row>
                    <Carousel responsive={responsive} autoPlaySpeed={3000} autoPlay={true} infinite={true}>
                        {
                            children.map((list, index) => {
                                return (
                                    <Col className="artical" key={index}>
                                        <div className="count-text">{index + 1}</div>
                                        <Card >
                                            <Card.Img variant="top" src={list.image} alt={title} loading="lazy" />
                                            <Card.Body>
                                                <Card.Text className='card-category'>{title} </Card.Text>
                                                <Card.Link href={`${BASE_PATH}${list.categoryurl}/${list.articleurl}`} >
                                                    <Card.Title className='word-line-limit'>{list.title}</Card.Title>
                                                </Card.Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Carousel>
                </Row >
                <ViewMoreButtonMobile redirect={url} />
            </Container >
        </section >
    )
}

export default SlideCards2;

