import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Container, Row } from 'react-bootstrap';
import './videos.css';
import ViewMoreButtonDesktop, { ViewMoreButtonMobile } from '../WebButton/ViewMoreButton';
import { useAppContext } from '../Context/AppContext';
import youtube from '../logos/youtube.png'

const Videos = (props) => {
    const { children, title, url } = props;
    const { BASE_PATH } = useContext(useAppContext);
    return (
        <section className='videos'>
            <Container>
                <Row>
                    <div className='whitetitle'>
                        <h2>{title}</h2>
                        <ViewMoreButtonDesktop redirect={url} />
                    </div>
                </Row>
                <Row>
                    {
                        children.map((data, index) => {
                            const { articleurl, categorytitle, categoryurl, date, image, title } = data;
                            return (
                                <Col lg={4} md={4} sm={6} xs={6} className='p-2' key={index}>
                                    <Card >
                                        <div className='position-relative'>
                                            <Card.Img variant="top" src={image} alt={title} loading="lazy" />
                                            <Card.Link href={`${BASE_PATH}${categoryurl}/${articleurl}`} className='icon'>
                                                <img src={youtube} alt="youtube" />
                                            </Card.Link>
                                        </div>
                                        <Card.Body>
                                            <Card.Text className='card-category'>{categorytitle} </Card.Text>
                                            <Card.Link href={`${BASE_PATH}${categoryurl}/${articleurl}`}>
                                                <Card.Title className='word-line-limit'>{title}</Card.Title>
                                            </Card.Link>
                                        </Card.Body>
                                    </Card>
                                </Col >
                            )

                        })
                    }
                </Row>
                <ViewMoreButtonMobile redirect={url} />
            </Container>
        </section >
    );
}

export default Videos;







