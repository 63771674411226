import React from "react";
import { Container, Row } from "react-bootstrap";
import parse from 'html-react-parser';

const OtherPages = (props) => {
    const { data, title } = props;
    document.body.scrollTop = 0
    return (
        <Container className="">
            <Row className="p-5">
                <div className="whitetitle">
                    <h2>{title}</h2>
                </div>
            </Row>
            <Row className="p-5">
                {parse(data)}
            </Row>
        </Container>
    )
}

export default OtherPages;