import React, { createContext } from "react";

export const useAppContext = createContext();

const AppContext = ({ children }) => {
    // const BASE_PATH = "http://localhost:3000/";
    const BASE_PATH = "https://mobilemasala.com/";

    const values = {
        BASE_PATH
    }

    return (
        <useAppContext.Provider value={values}>
            {children}
        </useAppContext.Provider>
    );
}

export default AppContext;