import React, { useContext } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import './RelatedArticel.css';
import ViewMoreButtonDesktop, { ViewMoreButtonMobile } from "../WebButton/ViewMoreButton";
import { useAppContext } from "../Context/AppContext";


const RealtedArticel = (props) => {
    const { children, title, url } = props;

    const { BASE_PATH } = useContext(useAppContext);

    return (
        <section className="relatedarticel">
            <Container>
                <Row>
                    <div className='whitetitle'>
                        <h2>{title}</h2>
                        <ViewMoreButtonDesktop redirect={children[0].categoryurl} />
                    </div>
                </Row>
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12}  >
                        <Card >
                            <Card.Img variant="top" src={children[0].image} alt={title} loading="lazy" />
                            <Card.Body>
                                <Card.Text className='card-category'>{children[0].categorytitle} </Card.Text>
                                <Card.Link href={`${BASE_PATH}${children[0].categoryurl}/${children[0].articleurl}`}>
                                    <Card.Title className="word-line-limit3 first-artical-title">{children[0].title}</Card.Title>
                                </Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} >
                        <Row>
                            <Col lg={6} md={6} sm={6} xs={6} >
                                <Card >
                                    <Card.Img variant="top" src={children[1].image} alt={title} loading="lazy" />
                                    <Card.Body>
                                        <Card.Text className='card-category'>{children[1].categorytitle} </Card.Text>
                                        <Card.Link href={`${BASE_PATH}${children[1].categoryurl}/${children[1].articleurl}`}>
                                            <Card.Title className='word-line-limit'>{children[1].title}</Card.Title>
                                        </Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6} >
                                <Card >
                                    <Card.Img variant="top" src={children[2].image} alt={title} loading="lazy" />
                                    <Card.Body>
                                        <Card.Text className='card-category'>{children[2].categorytitle} </Card.Text>
                                        <Card.Link href={`${BASE_PATH}${children[2].categoryurl}/${children[2].articleurl}`}>
                                            <Card.Title className='word-line-limit'>{children[2].title}</Card.Title>
                                        </Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={6} xs={6} >
                                <Card >
                                    <Card.Img variant="top" src={children[3].image} alt={title} loading="lazy" />
                                    <Card.Body>
                                        <Card.Text className='card-category'>{children[3].categorytitle} </Card.Text>
                                        <Card.Link href={`${BASE_PATH}${children[3].categoryurl}/${children[3].articleurl}`}>
                                            <Card.Title className='word-line-limit'>{children[3].title}</Card.Title>
                                        </Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6} >
                                <Card >
                                    <Card.Img variant="top" src={children[4].image} alt={title} loading="lazy" />
                                    <Card.Body>
                                        <Card.Text className='card-category'>{children[4].categorytitle} </Card.Text>
                                        <Card.Link href={`${BASE_PATH}${children[4].categoryurl}/${children[4].articleurl}`}>
                                            <Card.Title className='word-line-limit'>{children[4].title}</Card.Title>
                                        </Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ViewMoreButtonMobile redirect={children[0].categoryurl} />
            </Container>
        </section >
    )
}

export default RealtedArticel;