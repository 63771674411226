import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Cookies from "js-cookie";
import Meta from "../Meta/Meta";
import './AmpStoriess.css';
import { useAppContext } from "../Context/AppContext";
import Error from "../Error";


const AmpStoriesCard = () => {
    if (!Cookies.get('lg')) {
        Cookies.set('lg', 'en');
    }
    const { BASE_PATH } = useContext(useAppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const { storiename } = useParams();
    const { pathname } = useLocation();
    const categoryname = pathname.split('/');
    useEffect(() => {
        fetchData();
    }, [])


    const fetchData = async () => {
        const response = await fetch("https://mobilemasala.com:9093/en/articlepage?type=stories&category=" + categoryname[1] + "&article=" + storiename, {
            method: "POST"
        })
        const finaldata = await response.json()
        setData(finaldata.data)
        setIsLoading(false);
    }

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100" >
                <p>Loading</p>
            </div>
        )
    }

    if (data.articledata === null) {
        return (
            <Error />
        )
    }

    const { images, nextarticalurl = 0 } = data.articledata;
    return (
        <>
            <Meta metadata={data.metadata} />
            <HelmetProvider>
                <Helmet>
                    <script src="https://cdn.ampproject.org/amp-story-player-v0.js" type="text/javascript"></script>
                    <script async src="https://cdn.ampproject.org/v0.js"></script>
                    <script async custom-element="amp-story" src="https://cdn.ampproject.org/v0/amp-story-1.0.js"></script>
                </Helmet>
            </HelmetProvider>
            <amp-story standalone id="amp-story" style={{ zIndex: '9999' }}>
                {
                    images.map((value, index, row) => {
                        return (
                            <amp-story-page key={index}>
                                <amp-story-grid-layer template="fill">
                                    <amp-img src={value.image}
                                        layout="responsive">
                                    </amp-img>
                                </amp-story-grid-layer>
                                {
                                    value.title && (
                                        <amp-story-grid-layer template="vertical" style={{ background: "linear-gradient(0deg, #000 .625%,#000 3.75%,rgba(0, 0, 0, 0) 50.625%)" }}>

                                            {(row.length === index + 1 && nextarticalurl != 0) ? (
                                                <>
                                                    <p style={{ position: "absolute", bottom: "10%", textAlign: "center", color: "white", fontSize: "16px", fontWeight: "500", padding: "0 25px" }}>{value.title}
                                                    </p>
                                                    <amp-story-page-outlink layout="nodisplay">
                                                        <a href={`${BASE_PATH}photo-stories/${nextarticalurl}`}>Next Storie</a>
                                                    </amp-story-page-outlink>
                                                </>
                                            )
                                                :
                                                (<>
                                                    <p style={{ position: "absolute", bottom: "10px", textAlign: "center", color: "white", fontSize: "16px", fontWeight: "500", padding: "0 25px" }}>{value.title}
                                                    </p>
                                                </>)
                                            }
                                        </amp-story-grid-layer>
                                    )
                                }
                            </amp-story-page>
                        )
                    })
                }

            </amp-story >
        </>

    )
}

export default AmpStoriesCard;