import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import './Slider.css';
import { useContext } from 'react';
import { useAppContext } from '../Context/AppContext';

const Slider = (props) => {
    const { list } = props;
    const { BASE_PATH } = useContext(useAppContext);
    return (
        <section className='slider pt-1'>
            <Container className='p-0' >
                <Row>
                    <Carousel indicators={false}>
                        {
                            list.map((artical, index) => {
                                const { articleurl, categorytitle, categoryurl, date, image, title } = artical;
                                return (
                                    <Carousel.Item interval={2000} key={index}>
                                        <Link to={`${BASE_PATH}${categoryurl}/${articleurl}`}>
                                            <Carousel.Caption>
                                                <h3>{title}</h3>
                                            </Carousel.Caption>
                                            <img src={image} alt={index + title} loading="lazy" />
                                        </Link>
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                </Row>
            </Container>
        </section>
    );
}

export default Slider;