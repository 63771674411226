import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './menu.css';
import Cookies from 'js-cookie';
import { useAppContext } from '../Context/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import facebook from '../logos/facebook.png'
import instagram from '../logos/instagram.png'
import threads from '../logos/threads.png'
import youtube from '../logos/youtube.png'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';


const Menu = (props) => {
    const { BASE_PATH } = useContext(useAppContext);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    const { navbardata, companydetails } = props;
    const param2 = window.location.pathname.split('/');
    if (param2.length >= 3 && param2[1] == 'visualstories') {
        if (param2[2].length > 0) {
            return ''
        }
    }
    const handleLanguage = (e) => {
        Cookies.set('lg', e)
        window.location.href = "/";
    }

    const handlesearch = (e) => {
        setSearch(e.target.value)
    }

    const searchbutton = () => {
        if (search.length > 0 && search.trim() != '') {
            navigate("/search/" + search);
            window.location.reload();
        }
    }


    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
                <Container>

                    <NavDropdown className='d-lg-none d-block remove-arrows no-content'
                        title={
                            <img src={companydetails.languageicon} alt="la-icon" style={{ 'width': '30px' }} />
                        }
                        id={`offcanvasNavbarDropdown-expand-lg`}
                        key={'mm'}
                    >
                        <NavDropdown.Item href="#" onClick={() => handleLanguage('en')}  >
                            English
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#" onClick={() => handleLanguage('tl')} >
                            తెలుగు
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#" onClick={() => handleLanguage('hi')} >
                            हिन्दी
                        </NavDropdown.Item>
                    </NavDropdown>
                    <Navbar.Brand href="/" className='m-0'> <img src={companydetails.companylogo} alt="logo" loading="lazy" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#2c2c2c" className="bi bi-grid-fill" viewBox="0 0 16 16">
                            <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
                        </svg>
                    </ Navbar.Toggle>

                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-lg`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>

                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end align-items-lg-center flex-grow-1">
                                {
                                    navbardata.map((data, index) => {
                                        if (typeof data.subtitle !== 'undefined') {
                                            return (
                                                <NavDropdown
                                                    title={data.title}
                                                    id={`offcanvasNavbarDropdown-expand-lg`}
                                                    key={index}
                                                >
                                                    {data.subtitle.map((subdata, subindex) => {
                                                        return <NavDropdown.Item href={`${BASE_PATH}${subdata.url}`} key={subindex}>{subdata.title}</NavDropdown.Item>
                                                    })}
                                                </NavDropdown>
                                            )
                                        }
                                        return (
                                            <Nav.Link href={`${BASE_PATH}${data.url}`} key={index}> {data.title}</Nav.Link>
                                        )
                                    })
                                }
                                <>
                                    <InputGroup >
                                        <Form.Control
                                            placeholder="Search"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            className='search'
                                            value={search}
                                            onChange={(e) => handlesearch(e)}
                                        />
                                        <InputGroup.Text id="basic-addon2" onClick={() => searchbutton()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                            </svg></InputGroup.Text>
                                    </InputGroup>
                                </>

                                <NavDropdown className='remove-arrows no-content d-lg-block d-none'
                                    title={
                                        <img src={companydetails.languageicon} alt="la-icon" style={{ 'width': '30px' }} />
                                    }
                                    id={`offcanvasNavbarDropdown-expand-lg`}
                                    key={'mm'}
                                >
                                    <NavDropdown.Item href="#" onClick={() => handleLanguage('en')}  >
                                        English
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#" onClick={() => handleLanguage('tl')} >
                                        తెలుగు
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#" onClick={() => handleLanguage('hi')} >
                                        हिन्दी
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            <div className='icons d-lg-none d-block '>
                                <Link to={companydetails.companyfacebook} target='_blank'>
                                    <img src={facebook} alt="Facebook" />
                                </Link>
                                <Link to={companydetails.companyinstagram} target='_blank'>
                                    <img src={instagram} alt="instagram" />
                                </Link>
                                <Link to={companydetails.companythreads} target='_blank'>
                                    <img src={threads} alt="threads" />
                                </Link>
                                <Link to={companydetails.companyyoutube} target='_blank'>
                                    <img src={youtube} alt="youtube" />
                                </Link>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar >
        </>
    )
}

export default Menu;