import React, { useEffect } from "react";

const VerticalAd = (props) => {
    const { adnumber, adslot, adsize, postion } = props;
    useEffect(() => {
        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(function () {
            window.googletag.defineSlot(adslot, adsize, 'v-ad' + adnumber)
                .addService(window.googletag.pubads().set('page_url', 'mobilemasala.com'));
            window.googletag.enableServices();
        });
        return () => {
            window.googletag = null;
        };
    }, []);

    useEffect(() => {
        if (window.googletag) {
            window.googletag.cmd.push(function () {
                window.googletag.display('v-ad' + adnumber);
            });
        }
    }, []);

    return (
        <>
            {postion == 'left' ? (
                <>
                    <div className="d-none d-xxl-block" style={{ width: '10%', top: '15%', left: '15px', position: 'fixed', backgroundColor: 'white' }}>
                        <div id={`v-ad${adnumber}`}></div>
                    </div>
                </>
            ) :
                (
                    <>
                        <div className="d-none d-xxl-block" style={{ width: '10%', top: '15%', right: '55px', position: 'fixed', backgroundColor: 'white' }}>
                            <div id={`v-ad${adnumber}`}></div>
                        </div>
                    </>
                )

            }
        </>

    )
}

export default VerticalAd;
