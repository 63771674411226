import React, { useEffect, useState } from 'react';
import { Col, Container, Pagination, Row } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import Meta from '../Meta/Meta';
import CategoryCard from './CategoryCard';
import HorizontalAd from '../Ads/HorizontalAd';
import './Category.css';

const Category = () => {

    if (!Cookies.get('lg')) {
        Cookies.set('lg', 'en');
    }

    const { categoryname, searchdata } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const [urlpg, setUrlPg] = useState();
    const [searchParams, setSearchParams] = useSearchParams()
    const fetchData = async (pagenumber = '1') => {
        if (searchdata) {
            const response = await fetch("https://mobilemasala.com:9093/" + Cookies.get('lg') + "/search?name=" + searchdata + "&page=" + pagenumber, {
                method: "POST"
            })
            const finaldata = await response.json()
            setData(finaldata.data)
            setIsLoading(false);
        } else {
            const response = await fetch("https://mobilemasala.com:9093/" + Cookies.get('lg') + "/categorypage?name=" + categoryname + "&page=" + pagenumber, {
                method: "POST"
            })
            const finaldata = await response.json()
            setData(finaldata.data)
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100" >
                <p>Loading</p>
            </div>
        )
    }

    const { metadata, categorydata, pagination, pagetitle, ads = 0 } = data;

    const handlePagination = (pnumber) => {
        setUrlPg(pnumber);
        fetchData(pnumber);
        setSearchParams(`?${new URLSearchParams({ page: pnumber })}`);
        document.body.scrollTop = 0
    }

    return (
        <>
            <Meta metadata={metadata} />

            <section className='category'>
                <Container>
                    <Row>
                        <div className='whitetitle' id="title">
                            <h2>{pagetitle}</h2>
                        </div>
                    </Row>
                    {categorydata.length > 0 ? (

                        categorydata.map((category, index) => {
                            return (
                                <>
                                    <Row key={index}>
                                        {
                                            category.map((singlecard, i) => {
                                                return <CategoryCard data={singlecard} key={i} />
                                            })
                                        }

                                    </Row>
                                    {ads.length > 0 ? (
                                        <>
                                            <HorizontalAd adslot={ads[index].adslot} adsizes={ads[index].adsizes} adnumber={ads[index].adnumber} />
                                        </>
                                    ) : (<></>)
                                    }
                                </>
                            )
                        })
                    ) : (
                        <><div className='d-flex align-items-center justify-content-center vh-100'>
                            <p>No Data Found</p>
                        </div></>
                    )
                    }
                    {
                        <Row>
                            <Col className='d-flex justify-content-center pg'>
                                <Pagination size="sm" >
                                    {pagination != null ?
                                        pagination.paginationurls.map((data, value) => {
                                            for (let key in data) {
                                                return (
                                                    <Pagination.Item key={value} active={pagination.paginationnumber === data[key]} onClick={() => handlePagination(data[key])}>
                                                        {key}
                                                    </Pagination.Item>
                                                )
                                            }
                                        }) : null
                                    }
                                </Pagination>
                            </Col>
                        </Row>
                    }
                </Container>
            </section >
        </>
    );
}

export default Category;







